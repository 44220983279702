<template>
  <div class="elite-tabs-wrapper-content">
    <h3 class="mb-4">{{ event.dealer_name }}</h3>

    <div class="col-12">
      <div class="col-12">
        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.NAME") }}</dt>
          <dd class="col-sm-8">
            <div v-html="event.name"></div>
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.TYPE") }}</dt>
          <dd class="col-sm-8">
            {{ event.type }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">
            {{ $t("COMMON.START_AT") }}
          </dt>
          <dd class="col-sm-8">
            {{ event.start_at }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.END_AT") }}</dt>
          <dd class="col-sm-8">
            {{ event.end_at }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="event.created_at">
            {{ $formatDate(event.created_at) }}
          </dd>
        </dl>

        <dl class="row">
          <dt class="col-sm-4">{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd class="col-sm-8" v-if="event.updated_at">
            {{ $formatDate(event.updated_at) }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "event-view-global",

  components: {},

  props: ["event"],

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    event(event) {},
  },
};
</script>
