var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"elite-tabs-wrapper-content"},[_c('div',{staticClass:"row property-tab-title"},[_c('div',{staticClass:"text-left"},[_c('h3',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(`${_vm.auction.name}`)+" - "+_vm._s(_vm.$t("COMMON.VEHICLES"))+" ")])])]),_c('vehicle-list-table',{ref:"propertyList",attrs:{"isSelectable":true,"filter-auction-id":_vm.auction.id}}),(_vm.showModal)?_c('modal',{attrs:{"show":_vm.showModal,"modal-classes":"modal-secondary","size":"lg"}},[_c('template',{slot:"header"},[_c('div',{staticClass:"d-flex justify-content-center font-weight-700 text-uppercase w-100"},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES.PRINT_MODAL_HEADER"))+" ")])]),_c('form',{staticClass:"add-form pb-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.AUCTION')}(*)`,"placeholder":_vm.$t('COMMON.AUCTION')}},[_c('auction-selector',{attrs:{"placeholder":_vm.$t('COMMON.AUCTION'),"auction":_vm.details.auction,"filterable":true,"showAll":false},on:{"auctionChanged":(auctionId) => {
                _vm.details.auction = auctionId;
              }}})],1)],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.CITY')} (*)`,"placeholder":_vm.$t('COMMON.CITY')},model:{value:(_vm.details.city),callback:function ($$v) {_vm.$set(_vm.details, "city", $$v)},expression:"details.city"}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.DATE')} (*)`,"placeholder":_vm.$t('COMMON.DATE')},model:{value:(_vm.details.date),callback:function ($$v) {_vm.$set(_vm.details, "date", $$v)},expression:"details.date"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"placeholder":_vm.$t('COMMON.DATE'),"config":{
              allowInput: true,
              enableTime: false,
              type: 'date',
              locale: _vm.$flatPickrLocale(),
            }},model:{value:(_vm.details.date),callback:function ($$v) {_vm.$set(_vm.details, "date", $$v)},expression:"details.date"}})],1)],1)]),_c('template',{slot:"footer"},[(_vm.selectedProperties.length !== 0)?_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.details.city === '' || _vm.details.date === '' || _vm.details.auction === ''},on:{"click":_vm.printSelection}},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES.PRINT"))+" ")]):_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.details.city === '' || _vm.details.date === '' || _vm.details.auction === ''},on:{"click":_vm.print}},[_vm._v(" "+_vm._s(_vm.$t("PROPERTIES.PRINT"))+" ")]),_c('button',{staticClass:"btn btn-link ml-auto",attrs:{"type":"button"},on:{"click":function($event){_vm.showModal = false}}},[_vm._v(" "+_vm._s(_vm.$t("COMMON.CLOSE"))+" ")])])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }