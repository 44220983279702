<template>
  <div class="page-wrapper-table">
    <div class="page-wrapper-table-header">
      <el-select
        class="select-primary pagination-select"
        v-model="pagination.perPage"
        :placeholder="$t('COMMON.PER_PAGE')"
      >
        <el-option
          class="select-primary"
          v-for="item in pagination.perPageOptions"
          :key="item"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>

      <organization-selector
        v-if="
          !filterOrganization &&
          $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        "
        @organizationChanged="
          (organizationId) => {
            selectedOrganization = organizationId;
          }
        "
      />

      <locations-selector
        v-if="$currentUserCan($permissions.PERM_VIEW_ANY_LOCATIONS)"
        :organization="selectedOrganization"
        @locationsChanged="(locations) => (selectedLocations = locations)"
      />

      <el-select
        class="select-primary pagination-select"
        v-model="status"
        :placeholder="$t('COMMON.STATUS')"
      >
        <el-option class="select-primary" label="" value="" />

        <el-option
          class="select-primary"
          :label="$t('COMMON.DRAFT')"
          :value="VEHICLE_STATUS_DRAFT"
        />

        <el-option
          class="select-primary"
          :label="$t('COMMON.COMPLETED')"
          :value="VEHICLE_STATUS_COMPLETED"
        />
      </el-select>

      <el-select
        class="select-primary pagination-select"
        v-model="isValidated"
        :placeholder="$t('COMMON.VALIDATED')"
      >
        <el-option class="select-primary" label="" :value="null" />

        <el-option
          class="select-primary"
          :label="$t('COMMON.YES')"
          :value="1"
        />

        <el-option class="select-primary" :label="$t('COMMON.NO')" :value="0" />
      </el-select>

      <el-select
        class="select-primary pagination-select"
        v-model="auctionStatus"
        :placeholder="$t('VEHICLES.AUCTION_STATUS')"
      >
        <el-option class="select-primary" label="" :value="null" />

        <el-option
          class="select-primary"
          :label="$t('VEHICLES.AUCTION_STATUS_SOON')"
          :value="VEHICLE_AUCTION_STATUS_SOON.toLowerCase()"
        />

        <el-option
          class="select-primary"
          :label="$t('VEHICLES.AUCTION_STATUS_ONGOING')"
          :value="VEHICLE_AUCTION_STATUS_ONGOING.toLowerCase()"
        />

        <el-option
          class="select-primary"
          :label="$t('VEHICLES.AUCTION_STATUS_COMPLETED')"
          :value="VEHICLE_AUCTION_STATUS_COMPLETED.toLowerCase()"
        />
      </el-select>

      <base-input
        v-model="query"
        type="search"
        prepend-icon="far fa-search"
        :placeholder="$t('COMMON.SEARCH')"
        clearable
      />

      <base-button
        class="btn base-button notification bg-dark btn-icon btn-fab btn-info btn-sm mb-4"
        icon
        size="sm"
        @click="resetFilters"
      >
        <span class="btn-inner--icon">
          <i class="fa fa-undo"></i>
        </span>
      </base-button>
    </div>

    <div class="page-wrapper-table-body">
      <div class="page-wrapper-table-body-inner">
        <el-table
          class="align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="!loading ? vehicles : []"
          @sort-change="sortChange"
        >
          <div class="table-loading" slot="empty" v-if="loading">
            <img src="/img/loading.gif" style="height: 100px; width: 100px" />
          </div>

          <el-table-column min-width="100">
            <template v-slot="{ row }">
              <div class="user-image">
                <img
                  v-if="row.exterior_photos?.length > 0"
                  :src="row.exterior_photos[0]"
                  class="avatar rounded-circle"
                />
                <img
                  v-else
                  src="/img/camera.svg"
                  class="avatar rounded-circle p-2"
                />
              </div>
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('VEHICLES.VIN')"
            prop="vin"
            min-width="180"
          />

          <el-table-column
            :label="$t('VEHICLES.MODEL_YEAR')"
            prop="model_year"
            min-width="150"
          />

          <el-table-column
            :label="$t('VEHICLES.MAKE')"
            prop="make"
            min-width="150"
          />

          <el-table-column
            :label="$t('VEHICLES.MODEL')"
            prop="model"
            min-width="150"
          />

          <el-table-column
            :label="$t('VEHICLES.SERIES')"
            prop="series"
            min-width="150"
          />

          <el-table-column
            :label="$t('VEHICLES.STYLE')"
            prop="style"
            min-width="150"
          />

          <el-table-column
            :label="$t('VEHICLES.ENGINE_DESCRIPTION')"
            prop="engine_description"
            min-width="150"
          />

          <el-table-column
            :label="$t('COMMON.STATUS')"
            prop="status"
            min-width="180"
          >
            <badge
              slot-scope="{ row }"
              :type="
                row.status.toLowerCase() ===
                VEHICLE_STATUS_COMPLETED.toLowerCase()
                  ? 'success'
                  : 'warning'
              "
            >
              {{ $t(`COMMON.${row.status?.toUpperCase() ?? "DRAFT"}`) }}
            </badge>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.VALIDATED')"
            prop="is_validated"
            min-width="180"
          >
            <badge
              slot-scope="{ row }"
              :type="row.is_validated ? 'success' : 'warning'"
            >
              {{ row.is_validated ? $t(`COMMON.YES`) : $t(`COMMON.NO`) }}
            </badge>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.CREATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.created_at) }}
            </template>
          </el-table-column>

          <el-table-column
            :label="$t('COMMON.UPDATED_AT')"
            prop="created_at"
            sortable="custom"
            min-width="220"
          >
            <template v-slot="{ row }">
              {{ $formatDate(row.updated_at) }}
            </template>
          </el-table-column>

          <el-table-column fixed="right" min-width="120">
            <div slot-scope="{ row }" class="table-actions">
              <el-tooltip
                :content="$t('COMMON.VIEW')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_VIEW_VEHICLES)"
              >
                <a
                  type="text"
                  @click="viewVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-expand-alt"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                :content="$t('COMMON.EDIT')"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_EDIT_VEHICLES)"
              >
                <a
                  type="text"
                  @click="editVehicle(row)"
                  class="table-action"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-edit"></i>
                </a>
              </el-tooltip>

              <el-tooltip
                content="Delete"
                placement="top"
                v-if="$currentUserCan($permissions.PERM_DELETE_VEHICLES)"
              >
                <a
                  type="text"
                  @click="deleteVehicle(row)"
                  class="table-action table-action-delete"
                  data-toggle="tooltip"
                  style="cursor: pointer"
                >
                  <i class="fal fa-trash-alt"></i>
                </a>
              </el-tooltip>
            </div>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div slot="footer" class="page-wrapper-table-footer">
      <p class="card-category">
        {{
          $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
            from: total ? from + 1 : 0,
            to: to,
            of: total,
          })
        }}

        <span v-if="selectedRows.length">
          &nbsp; &nbsp;
          {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
        </span>
      </p>

      <base-pagination
        class="pagination-no-border"
        v-model="pagination.currentPage"
        :per-page="pagination.perPage"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import LocationsSelector from "@/components/LocationsSelector.vue";
import GallerySelector from "@/components/GallerySelector.vue";
import {
  VEHICLE_STATUS_COMPLETED,
  VEHICLE_STATUS_DRAFT,
  VEHICLE_AUCTION_STATUS_SOON,
  VEHICLE_AUCTION_STATUS_ONGOING,
  VEHICLE_AUCTION_STATUS_COMPLETED,
} from "@/constants/vehicles";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "vehicle-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    LocationsSelector,
    GallerySelector,
    flatPicker,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterAuctionId: {
      type: String,
      default: null,
      description: "Auction id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-created_at",
      total: 0,
      VEHICLE_STATUS_COMPLETED,
      VEHICLE_STATUS_DRAFT,
      VEHICLE_AUCTION_STATUS_SOON,
      VEHICLE_AUCTION_STATUS_ONGOING,
      VEHICLE_AUCTION_STATUS_COMPLETED,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      vehicles: [],
      loading: true,
      selectedOrganization: null,
      selectedLocations: null,
      status: null,
      isValidated: null,
      auctionStatus: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    filterAuctionId: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedLocations: {
      handler: "getListDebounced",
      immediate: true,
    },
    status: {
      handler: "getListDebounced",
      immediate: true,
    },
    isValidated: {
      handler: "getListDebounced",
      immediate: true,
    },
    auctionStatus: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    resetFilters() {
      this.query = null;
      this.selectedOrganization = null;
      this.selectedLocations = null;
      this.status = null;
      this.isValidated = null;
      this.auctionStatus = null;
    },
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,allowedLocations",
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }

        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        if (this.selectedLocations) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              allowedLocations: this.selectedLocations.map((loc) => loc.id),
            },
          };
        }

        if (this.filterAuctionId) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction: this.filterAuctionId,
            },
          };
        }

        if (this.status) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              status: this.status,
            },
          };
        }

        if (this.isValidated != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              is_validated: this.isValidated,
            },
          };
        }

        if (this.auctionStatus != null) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              auction_status: this.auctionStatus,
            },
          };
        }

        await this.$store.dispatch("vehicles/list", params);
        this.vehicles = this.$store.getters["vehicles/list"];
        this.total = this.$store.getters["vehicles/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    deleteVehicle(vehicle) {
      this.$emit("onDeleteVehicle", vehicle);
    },

    viewVehicle(vehicle) {
      this.$emit("onViewVehicle", vehicle);
    },

    editVehicle(vehicle) {
      this.$emit("onEditVehicle", vehicle);
    },

    validateVehicle(vehicle) {
      this.$emit("onValidateVehicle", vehicle);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },
  },
};
</script>
