<template>
  <div>{{ modelData }}</div>
</template>

<script>
export default {
  props: {
    modelData: {
      type: String,
      required: true,
    },
  },
};
</script>
