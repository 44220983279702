<template>
  <modal
    v-if="showModal"
    :show="showModal"
    modal-classes="modal-secondary"
    size="lg"
  >
    <form class="add-form" @submit.prevent="">
      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.MINIMUM_AMOUNT')} (*)`"
          :placeholder="$t('COMMON.MINIMUM_AMOUNT')"
          v-model="auctionFee.amount_min"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount_min" />
      </div>

      <div class="form-wrapper">
        <base-input
          :label="`${$t('COMMON.MAXIMUM_AMOUNT')}`"
          :placeholder="$t('COMMON.MAXIMUM_AMOUNT')"
          v-model="auctionFee.amount_max"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.amount_max" />
      </div>

      <div class="form-wrapper w-100">
        <base-input
          :label="`${$t('COMMON.MRC_FEES')} (*)`"
          :placeholder="$t('COMMON.MRC_FEES')"
          v-model="auctionFee.mrc_fee"
        >
        </base-input>
        <validation-error :errors="apiValidationErrors.mrc_fee" />
      </div>
    </form>

    <template slot="footer">
      <button
        v-if="!auctionFee.id"
        type="submit"
        class="btn btn-primary"
        @click="submitAuctionFeeForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.ADD_ITEM") }}
      </button>

      <base-button
        v-if="auctionFee.id"
        native-type="submit"
        type="primary"
        class="new-purchasesOrder-item--add"
        @click="submitAuctionFeeForm"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.UPDATE_ITEM") }}
      </base-button>

      <base-button
        v-if="auctionFee.id"
        type="danger"
        class="ml-3"
        @click="deleteAuctionFee(auctionFee.id)"
        :disabled="loading"
      >
        <span v-if="loading" class="btn-inner--icon">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
        {{ $t("COMMON.DELETE_ITEM") }}
      </base-button>

      <button
        type="button"
        class="btn btn-link ml-auto"
        @click="closeAuctionFeeModal"
        :disabled="loading"
      >
        {{ $t("COMMON.CLOSE") }}
      </button>
    </template>
  </modal>
</template>

<script>
import { Button } from "element-ui";
import { cloneDeep } from "lodash";
import formMixin from "@/mixins/form-mixin";
import requestErrorMixin from "@/mixins/request-error-mixin";
import ValidationError from "@/components/ValidationError.vue";
import { mapGetters } from "vuex";

export default {
  name: "auction-fee-form",

  components: {
    ValidationError,
    [Button.name]: Button,
  },

  mixins: [formMixin, requestErrorMixin],

  props: ["auction", "auctionFee", "showModal", "loading", "formErrors"],

  data() {
    return {
      auctionFees: [],
      lastFee: {},
    };
  },

  computed: {
    ...mapGetters("auctionFees", ["list"]),
  },

  methods: {
    closeAuctionFeeModal() {
      this.$emit("onCloseAuctionFeeModal", true);
    },

    submitAuctionFeeForm() {
      const auctionFeeData = cloneDeep(this.auctionFee);
      auctionFeeData.amount_min = parseFloat(auctionFeeData.amount_min);
      auctionFeeData.amount_max = parseFloat(auctionFeeData.amount_max);
      auctionFeeData.mrc_fee = parseFloat(auctionFeeData.mrc_fee);
      auctionFeeData.auction = this.auction;

      this.$emit("onSubmitAuctionFeeForm", auctionFeeData);
    },

    deleteAuctionFee(auctionFeeId) {
      this.$emit("onDeleteAuctionFee", auctionFeeId);
    },
  },

  mounted() {},

  watch: {
    list: {
      handler(newList) {
        this.auctionFees = newList;
        this.resetApiValidation();
        if (newList.length > 0) {
          this.lastFee = this.auctionFees[0];
          this.$emit("updateAuctionFeeModel", newList[0]);
        }
      },
      immediate: true,
    },

    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
  },
};
</script>
