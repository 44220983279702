<template>
  <div class="container-fluid">
    <auction-form
      :loading="loading"
      :auctionData="auction"
      :formErrors="formErrors"
      @auctionSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
    />
  </div>
</template>

<script>
import AuctionForm from "../partials/AuctionForm.vue";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import defaultAuction from "../defaultAuction";

export default {
  layout: "DashboardLayout",

  components: { AuctionForm },

  mixins: [alertLeave],

  data() {
    const auction = cloneDeep(defaultAuction);
    return {
      auction: auction,
      formErrors: null,
      loading: false,
    };
  },

  methods: {
    async handleSubmit(auction) {
      this.loading = true;
      const auctionData = cloneDeep(auction);

      try {
        await this.$store.dispatch("auctions/add", auctionData);
        this.$notify({
          type: "success",
          message: this.$t("AUCTIONS.AUCTION_ADDED"),
        });
        const auction = await this.$store.getters["auctions/auction"];
        this.$emit("onViewAuction", auction, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },
  },
};
</script>
